import { Allergen, Ingredient } from "../interfaces/Product";
import { QueryStringParameter, SortQueryStringParameter } from "./enums/EnumHelper";

import { CategoryName } from "../interfaces/Category";
import ErrorComponent from "../components/error/ErrorComponent";
import LoadingComponent from "../components/loading/LoadingComponent";
import React from "react";

export function getSortQueryString(categoryName: CategoryName):string | undefined {
    
    let queryString:string|undefined;

    switch (categoryName) {     
        case CategoryName.Salads:   
        case CategoryName.MeatDishes:
           queryString = QueryStringParameter.Price+':'+SortQueryStringParameter.Desc;
           break;     
        case CategoryName.Drinks:
        case CategoryName.Pizza:
            queryString = QueryStringParameter.Price+':'+SortQueryStringParameter.Asc;
           break;
    }

    return queryString;
}

export function renderLoadingOrError(isError:boolean, errorMessage:string, isLoading:boolean, loadingMessage:string) {       
    if(isError) {
       return <ErrorComponent message={errorMessage}/>;
    }
    else if(isLoading) {         
       return <LoadingComponent message={loadingMessage}/>;
    }
 }

 export function displayAllergens(allergens: Allergen[]) {
      
    let stringToReturn: string = "[";
    for(let i = 0; i < allergens.length; i++) {

       stringToReturn += allergens[i].letter_code;
       if(i+1 !== allergens.length) {
          stringToReturn += ',';
       }
    }

    stringToReturn += ']';

    return stringToReturn.toString();
 }

 export function displayIngredients(ingredients: Ingredient[]) {

    let tmpString: string = "";

    for(let i =0; i < ingredients.length; i++) {
       tmpString += ingredients[i].name;
       if(i+1 !== ingredients.length) {
          tmpString += ", ";
       }
    }

    return tmpString.toString();
 }