import '../toolbars/toolbar.scss';

import { DaSergioAlternateTextForImage, DaSergioTextFileName } from '../../helpers/Constants';
import { IonButton, IonButtons, IonIcon, IonImg, IonMenuButton, IonToolbar } from "@ionic/react";

import React from "react";
import { call } from 'ionicons/icons';
import { useHistory } from 'react-router';

const HeaderToolbarComponent: React.FC = () => {

    const history = useHistory();

    return(
        <IonToolbar className="header-ion-toolbar">                    
            <IonButtons slot="start">
                <IonMenuButton className="toolbar-ion-menu-button" autoHide />                
            </IonButtons> 
            <IonButton slot="end" className="ion-button-call" href="tel:0262225974"><IonIcon className="ion-button-icon" icon={call}></IonIcon>02622 259 74</IonButton>           
            <IonImg onClick={() => history.push('/') } className="toolbar-ion-img-da-sergio-headline"  
                src={process.env.REACT_APP_AWS_CDN_IMAGES_URL+DaSergioTextFileName}
                alt={DaSergioAlternateTextForImage}>                   
            </IonImg>                         
        </IonToolbar>
    );
}

export default HeaderToolbarComponent;