import '../content/content.scss';

import { DaSergioAlternateTextForImage, DaSergioLogoFileName192x233 } from '../../helpers/Constants';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonModal, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useState } from "react";

import { close } from 'ionicons/icons';

const CopyRight: React.FC = () => {
    const [showCopyRightModal, setShowCopyRightModal] = useState(false);
    
    return( 
        <>
            <IonModal isOpen={showCopyRightModal} swipeToClose={true} onDidDismiss={() => setShowCopyRightModal(false)}>
                <IonHeader>
                    <IonToolbar className="content-ion-toolbar">
                        <IonTitle>Impressum</IonTitle>
                        <IonButtons slot="end">
                            <IonButton className="content-ion-button" slot="icon-only" onClick={() => setShowCopyRightModal(false)}>
                            <IonIcon icon={close} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="content-ion-content"> 
                <IonItem className="content-ion-item-copyright" lines="none">             
                    <IonText className="da-sergio-text">
                    <p>
                        Pizzeria Ristorante da Sergio <br/>
                        Kaiserbrunngasse 8-12 <br/>
                        2700 Wiener Neustadt <br/>
                    </p>
                    <p>
                        Tel.: +43 2622 259 74 <br/>
                        Email: office(at)pizzeria-dasergio.at <br/>
                    </p>
                    <p>
                        UID: ATU67540556 <br/>
                    </p>
                    </IonText>
                    <IonImg className="da-sergio-logo"  
                        src={process.env.REACT_APP_AWS_CDN_IMAGES_URL+DaSergioLogoFileName192x233}
                        alt={DaSergioAlternateTextForImage}>
                    </IonImg>
                </IonItem> 
                </IonContent>
            </IonModal> 
            <IonText className="content-ion-text content-ion-text-copyright" onClick={() => setShowCopyRightModal(true)}>© 2023 Pizzeria Ristorante da Sergio | Impressum</IonText>
        </>
    );
}

export default CopyRight;