import '../sidemenu/SideMenuComponent.scss';

import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonMenuToggle, IonText } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router';
import { closeOutline, logoFacebook } from 'ionicons/icons';

import { AppPage } from './interfaces/AppPage';
import { DaSergioFaceBookUrl } from '../../helpers/Constants';
import React from 'react';

interface MenuProps extends RouteComponentProps { }
  
  const appPages: AppPage[] = [
    { title: 'Speisekarte', path: '/speisekarte'},
    { title: 'Standort', path: '/location'}
  ];
  
const SideMenuComponent: React.FC<MenuProps> = () => (
    
    <IonMenu 
    side="start" 
    className="ion-menu" 
    swipeGesture={true}
    type="overlay" 
    contentId="main">
        <IonItem className="ion-item-close-menu" lines="none">
            <IonMenuButton className="side-menu-ion-menu-button" slot="end">
            <IonIcon icon={closeOutline} />
            </IonMenuButton>
        </IonItem>
        <IonText class="da-sergio-text side-menu-text">
            <p><span className="side-menu-text-heading">Zustellung</span></p>
            <p>Mindestbestellwert<br/>
            innerhalb von Wiener Neustadt 15 €.<br/>
            Lieferung außerhalb <br/>auf Anfrage!
            </p>                            
        </IonText>
        <IonContent className="ion-menu-ion-content">
            <div className="content-container">
                <div className="content">
                    <IonList className="ion-list">
                    {appPages.map((appPage) => {
                        return (
                            <IonMenuToggle key={appPage.title} autoHide={false}>
                                <IonItem className="ion-list-item" button routerLink={appPage.path} detail={false} mode={"md"} routerDirection="none">                            
                                    <IonLabel className="da-sergio-heading-text">{appPage.title}</IonLabel>                                
                                </IonItem>
                            </IonMenuToggle>
                            );
                        })}               
                    </IonList>
                </div>            
                <div className="sticky-footer">  
                    <IonItem className="ion-content-item" lines="none" detail={false} mode={"md"}>                
                        <IonButton rel="noreferrer" href={DaSergioFaceBookUrl} target="_blank">
                            <IonIcon icon={logoFacebook} />           
                        </IonButton>            
                    </IonItem> 
                </div>
            </div>
        </IonContent>        
    </IonMenu>
);

export default withRouter(SideMenuComponent);
