import { CategoryName, getCategoryIdFromName } from "../interfaces/Category";
import { ProductsLoggerReducer, ProductsReducer, errorGettingProductsAction, gettingProductsAction, gotProductsAction } from "../state/reducers/ProductsReducer";
import { getProductsInCategoryFromStorageAsync, setProductsInStorageAsync } from "../storage/ProductsStorage";
import { useCallback, useEffect, useReducer, useState } from "react";

import { Product } from "../interfaces/Product";
import { ProductsState } from "../state/reducers/interfaces/ProductsState";
import { axiosInstance } from "../api-client/axiosInstance";
import { getSortQueryString } from "./HelperFunctions";

const useGetProducts = (categoryName:CategoryName) => {

    const [ errorMessage, setErrorMessage] = useState('');
    const [ loadingMessage, setLoadingMessage] = useState('');

    const productsReducer = process.env.REACT_APP_ENV === 'production' ? ProductsReducer : ProductsLoggerReducer;
    const [ productsInCategoryState, dispatch ] = useReducer(productsReducer, {
        productsInCategory: {}
     });

    const currentProductsInCategoryState:ProductsState = productsInCategoryState.productsInCategory[categoryName];  
    
    const sortProductsAfterFetch = useCallback((products: Product[]) => {
        if (categoryName === CategoryName.Pizza) {
    
           products.sort((a,b) => {
              if(a.price < 5) {
                 return 1;
              }
              else if (b.price < 5) {
                 return -1;
              }
              else {
                 return a.price-b.price;
              }
           });
        }
     },[categoryName]);
     
    useEffect(() => {      
  
        const getProducts = async () => {
            dispatch(gettingProductsAction(categoryName));      
            setLoadingMessage(categoryName + " werden geladen...")
            // retrieve product in a category from capacitor storage api 
            // if the cache is empty axios call and set in cache
            var products:Product[] | undefined = await getProductsInCategoryFromStorageAsync(categoryName);
    
            if(products !== undefined) {
                dispatch(gotProductsAction(categoryName, products!));               
            }
            else {
               
                await axiosInstance.get<Product[]>(`products/${getCategoryIdFromName(categoryName)}`, {
                    params: {
                        sort: getSortQueryString(categoryName)
                    }
                })
                .then((response) => {   
        
                    sortProductsAfterFetch(response.data);
                    dispatch(gotProductsAction(categoryName, response.data));
                    setProductsInStorageAsync(categoryName, response.data);
                })
                .catch((error) => {                  
                    setErrorMessage(categoryName+" konnten nicht geladen werden. Sie können uns gerne anrufen, wir freuen uns auf Ihren Anruf.");
                    dispatch(errorGettingProductsAction(categoryName));
                })};
        }

        getProducts();

    }, [dispatch, categoryName, sortProductsAfterFetch]);

    return { currentProductsInCategoryState, loadingMessage, errorMessage };
}

export default useGetProducts;