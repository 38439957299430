import { errorGettingAllergensAction, gettingAllergensAction, gotAllergensAction } from "../state/reducers/AllergensReducer";
import { getAllergensFromStorageAsync, setAllergensInStorageAsync } from "../storage/AllergensStorage";
import { useContext, useEffect, useState } from "react";

import { Allergen } from "../interfaces/Product";
import { AllergensContext } from "../state/providers/AllergensProvider";
import { axiosInstance } from "../api-client/axiosInstance";

const useGetAllergens = () => {

    const { allergensState , dispatch } = useContext(AllergensContext);
    const [ errorMessage, setErrorMessage] = useState('');
    const [ loadingMessage, setLoadingMessage ] = useState('');
    const [ callApiIndex, setCallApiIndex ] = useState(0);
    const [ shouldCallApi, setShouldCallApi ] = useState(false);
    
    const callApi = () => {
        setCallApiIndex((prevCallApiIndex) => prevCallApiIndex + 1);   

        if(!shouldCallApi) {
            // Api call should only be triggered manually 
            setShouldCallApi(true);      
        }         
    }

    useEffect(() => {

        const getAllergens = async () => {

            dispatch(gettingAllergensAction());
            setLoadingMessage("Allergeninformation werden geladen...");
            // retrieve product in a category from capacitor storage api 
            // if the cache is empty axios call and set in cache
            var allergens:Allergen[] | undefined = await getAllergensFromStorageAsync();

            if (allergens !== undefined) {
                dispatch(gotAllergensAction(allergens));               
            }
            else {
                await axiosInstance.get<Allergen[]>('allergens')
                    .then((response) => {   
                        dispatch(gotAllergensAction(response.data));
                        setAllergensInStorageAsync(response.data);
                    })
                    .catch((error) => {
                        setErrorMessage("Allergene konnten nicht geladen werden. Bitte probieren Sie es später noch einmal.");
                        dispatch(errorGettingAllergensAction())
                    })
            }
        }        
        
        if(shouldCallApi) {           
            getAllergens();
        }       

    }, [dispatch, shouldCallApi, callApiIndex]);

    return { allergensState, loadingMessage, errorMessage, callApi };
}

export default useGetAllergens;