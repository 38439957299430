import axios from "axios";

const timeoutInMilliseconds:number = 5000; 
 
const stage:string = process.env.REACT_APP_ENV === 'production' ? 'prod/' : 'dev/';

/** TODO: checkout  all parameters in create and use them if there is something awesome */ 
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AWS_API_GATEWAY_URL+stage,
  headers: { 'X-API-KEY': process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_AWS_API_GATEWAY_PROD_API_KEY : process.env.REACT_APP_AWS_API_GATEWAY_DEV_API_KEY },
  timeout: timeoutInMilliseconds
});