import { CategoriesState } from "./interfaces/CategoriesState";
import { Category } from "../../interfaces/Category";

enum CategoriesAction {
    Getting_Categories = 'GettingCategories',
    Got_Categories = 'GotCategories',
    Error_Getting_Categories = 'ErrorGettingCategories'
}

export function gettingCategoriesAction() {
    return {
        type: CategoriesAction.Getting_Categories
    } as const
}

export function gotCategoriesAction(categories: Category[]) {
    return {
        type: CategoriesAction.Got_Categories,
        categories: categories
    } as const
}

export function errorGettingCategoriesAction() {
    return {
        type: CategoriesAction.Error_Getting_Categories
    } as const
}

export type CategoriesActions = ReturnType<typeof gettingCategoriesAction | typeof gotCategoriesAction | typeof errorGettingCategoriesAction>;

export const CategoriesReducer = (state: CategoriesState, action: CategoriesActions): CategoriesState => {
    switch(action.type) {
        case CategoriesAction.Getting_Categories: {
            return { 
                ...state,
                isError: false,
                isLoading: true,                
                isLoaded: false
            };
        }
        case CategoriesAction.Got_Categories: {
            return {
                ...state,
                categories: action.categories,
                isError: false,
                isLoading: false,
                isLoaded: true
            };
        }
        case CategoriesAction.Error_Getting_Categories: {            
            return {
                ...state,
                isError: true,
                isLoading: false,
                isLoaded: false
            };
        }
        default:            

            return state;
    }
}

const logger = (reducer: any) => {
    const reducerWithLogger = (state: CategoriesState, action: CategoriesActions) => {
      console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
      console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
      console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state, action));
      return reducer(state,action);
    };
    
    return reducerWithLogger;
}

export const CategoriesLoggerReducer = logger(CategoriesReducer);