import GoogleMapReact, { MapOptions }  from 'google-map-react';
import { IonContent, IonFooter, IonHeader, IonPage, IonText, IonToolbar } from "@ionic/react";
import React, { useState } from "react";

import CustomGoogleMapsMarker from "./CustomGoogleMapsMarker";
import HeaderToolbar from '../toolbars/HeaderToolbarComponent';

const GoogleMapsComponent: React.FC = () => {
    
    const [center] = useState({lat: 47.81837180100801, lng: 16.248511047697167 });
    const [zoom] = useState(18);

    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    const mapOptions:MapOptions = {
        disableDefaultUI: true,
        mapTypeControl: true,
        streetViewControl: true,
        gestureHandling: "greedy",
        disableDoubleClickZoom: true,
        maxZoom: 18,
        minZoom: 13,
        styles: [{ 
            featureType: 'poi', 
            elementType: 'labels', 
            stylers: [{ visibility: 'simplified' }] 
        }]
    };
    
    return(
        <IonPage>
            <IonHeader>
                <HeaderToolbar/>                
            </IonHeader>
            <IonContent>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY! }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    options={mapOptions}
                    >
                    <CustomGoogleMapsMarker 
                        lat={center.lat}
                        lng={center.lng}
                    />
                </GoogleMapReact>
            </IonContent>
            <IonFooter>
                <IonToolbar className="google-maps-footer-ion-toolbar">
                    <IonText>
                        Pizzeria Ristorante da Sergio<br/>
                        Kaiserbrunngasse 8-12<br/>
                        2700 Wiener Neustadt
                    </IonText>
                </IonToolbar>
            </IonFooter>            
        </IonPage>
    );
}

export default GoogleMapsComponent;