import '../product/ProductsInCategoryComponent.scss';

import { CategoryName, getCategoryIdFromName } from '../../interfaces/Category';
import { IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonLabel, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React, { useContext } from 'react';
import { displayAllergens, displayIngredients, renderLoadingOrError } from '../../helpers/HelperFunctions';

import { CategoriesContext } from '../../state/providers/CategoriesProvider';
import HeaderToolbar from '../toolbars/HeaderToolbarComponent';
import StickyFooterComponent from '../toolbars/StickyFooterComponent';
import useGetProducts from '../../helpers/GetProductsCustomerReactHook';
import { useParams } from 'react-router';

interface CategoryParams {
   category_name: CategoryName
}

const ProductsInCategoryComponent: React.FC = () => {
   
   const { category_name } = useParams<CategoryParams>();   
   const { categoryState } = useContext(CategoriesContext);       
   const categoryName:string | undefined = categoryState?.categories.find(category => category.name === category_name)?.name; 
   const { currentProductsInCategoryState, loadingMessage, errorMessage } = useGetProducts(category_name);

   return(
      <IonPage>
         <IonHeader>
         <HeaderToolbar/>
            <IonToolbar className="ion-toolbar-navigation">
               <IonButtons slot="start">
                     <IonBackButton className="ion-toolbar-back-button" defaultHref="/"  />
               </IonButtons>
               <IonTitle className="products-ion-title da-sergio-heading-text">{categoryName}</IonTitle>
            </IonToolbar>
         </IonHeader>
         { currentProductsInCategoryState?.isLoaded 
         ?
            <>
               <IonContent className="products-ion-content">
                  <div className="content-container">
                     <div className="content">
                        <IonGrid className="da-sergio-max-width">
                           { category_name === CategoryName.MeatDishes
                              ? 
                                 <IonRow>
                                     <IonCol className="products-ion-grid-col">                                       
                                       <IonText >
                                          <p><span className="products-text-block da-sergio-text">Schnitzel und Cordon Bleu: Aufpreis Huhn 1 €.</span></p>
                                       </IonText>
                                    </IonCol>
                                 </IonRow>  
                              : null
                           }
                           <IonRow>
                              { currentProductsInCategoryState.products.filter(product => product.category_id === getCategoryIdFromName(category_name)).map((product, index) => {
                                 return(
                                    <IonCol
                                       className="products-ion-grid-col"
                                       size-xs="6" 
                                       size-md="4" 
                                       size-lg="3" 
                                       key={index}
                                    >
                                       <IonCard className="grid-col-ion-card">
                                          <img className="grid-col-ion-img" src={product.image_path} alt={product.name}/>
                                             <IonLabel className="grid-col-ion-label-price"> { product.price } € </IonLabel>
                                          <IonCardHeader>
                                             <IonCardTitle className="grid-col-ion-card-title">
                                                { product.name.toUpperCase() }                                          
                                             </IonCardTitle>                   
                                          </IonCardHeader>     
                                          <IonCardContent className="da-sergio-text grid-col-ion-card-content">
                                             { product.ingredients != null ? displayIngredients(product.ingredients) : null }                                             
                                             { // TODO: investigate syntax and refactor this
                                                product.ingredients != null ? <br/> : null
                                             } 
                                             { product.allergens != null ? displayAllergens(product.allergens) : null }                                       
                                          </IonCardContent>   
                                       </IonCard>     
                                    </IonCol>               
                                 );
                              })}
                           </IonRow>                         
                           { category_name === CategoryName.Pizza
                              ? 
                                 <IonRow>
                                    <IonCol className="products-ion-grid-col">
                                       <IonText>
                                          <p className="products-ion-grid-col-heading da-sergio-heading-text"><span>Zusätzliche Auflagen</span></p>
                                          <p><span className="products-text-block da-sergio-text">Meeresfrüchte, Shrimps, <br/>Hühnerfleisch, Prosciutto <br/>2.50 €</span></p>                                          
                                          <p><span className="products-text-block da-sergio-text">Ketchup, Mayonnaise, Ei, <br/>Knoblauch und Zwiebel <br/>0.50 €</span></p>
                                          <p><span className="products-text-block da-sergio-text">alle anderen Auflagen <br/>1.00 €</span></p>
                                       </IonText>
                                    </IonCol> 
                                 </IonRow>
                              : null                              
                           }
                           { category_name === CategoryName.Salads
                              ? 
                                 <IonRow>
                                     <IonCol className="products-ion-grid-col">                                       
                                       <IonText >
                                          <p><span className="products-text-block da-sergio-text">Zu jedem großen Salat erhalten Sie 2 Pizzastangen [A] dazu.</span></p>
                                          <p><span className="products-text-block da-sergio-text">Wir bereiten Ihren Salat auf Wunsch mit American [A,C,G], Joghurt [G], oder Knoblauchdressing [G] zu. </span></p>
                                       </IonText>
                                    </IonCol>
                                 </IonRow>  
                              : null
                           }                           
                        </IonGrid>  
                     </div>
                     <StickyFooterComponent/>                     
                  </div>       
               </IonContent> 
            </>
         :
            renderLoadingOrError(currentProductsInCategoryState?.isError, errorMessage, currentProductsInCategoryState?.isLoading, loadingMessage)
         }       
      </IonPage>
   );   
} 

export default ProductsInCategoryComponent;