/** Category of a product */
export interface Category {
    category_id: number;
    name: CategoryName;
    number_of_products: number;
    image_path: string;
}

export enum CategoryName {
    Pizza = "Pizza",
    NoodleDishes = "Nudelgerichte",
    NoodleDishesFromTheOven = "Nudelgerichte aus dem Ofen",
    VegetarianDishes = "Vegetarische Gerichte",
    FishDishes = "Fischgerichte",
    RiceDishes = "Reisgerichte",
    Salads = "Salate",
    MeatDishes = "Fleischgerichte",
    Desserts = "Dessert",
    Drinks = "Getränke",
    Soups = "Suppen"
}

export enum CategoryId {
    Pizza = 7,
    NoodleDishes = 8,
    NoodleDishesFromTheOven = 9,
    VegetarianDishes = 10,
    FishDishes = 11,
    RiceDishes = 12,
    Salads = 13,
    MeatDishes = 15,
    Desserts = 16,
    Drinks = 17,
    Soups = 18
}

export function getCategoryIdFromName(categoryName:CategoryName):number {

    let categoryId:number;

    switch(categoryName) {
        case CategoryName.Pizza:
            categoryId = CategoryId.Pizza;
            break;
        case CategoryName.NoodleDishes:
            categoryId = CategoryId.NoodleDishes;
            break;
        case CategoryName.NoodleDishesFromTheOven:
            categoryId = CategoryId.NoodleDishesFromTheOven;
            break;
        case CategoryName.VegetarianDishes:
            categoryId = CategoryId.VegetarianDishes;
            break;
        case CategoryName.FishDishes:
            categoryId = CategoryId.FishDishes;
            break;
        case CategoryName.RiceDishes:
            categoryId = CategoryId.RiceDishes;
            break;
        case CategoryName.Salads:
            categoryId = CategoryId.Salads;
            break;
        case CategoryName.MeatDishes:
            categoryId = CategoryId.MeatDishes;
            break;
        case CategoryName.Desserts:
            categoryId = CategoryId.Desserts;
            break;
        case CategoryName.Drinks:
            categoryId = CategoryId.Drinks;
            break;
        case CategoryName.Soups:
            categoryId = CategoryId.Soups;
            break;
        default:
            throw new Error(categoryName + " is not supported.");
    }

    return categoryId;
}