import './MenuComponent.scss';

import { DaSergioAlternateTextForImage, DaSergioLogoFileName192x233 } from '../../helpers/Constants';
import { IonBadge, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonItem, IonLabel, IonPage, IonRow, IonText, IonThumbnail } from '@ionic/react';

import HeaderToolbar from '../toolbars/HeaderToolbarComponent';
import React from 'react';
import StickyFooterComponent from '../toolbars/StickyFooterComponent';
import { renderLoadingOrError } from '../../helpers/HelperFunctions';
import useGetCategories from '../../helpers/GetCategoriesCustomReactHook';
import { useHistory } from 'react-router';

const MenuComponent: React.FC  = ()  => {
  
   const history = useHistory();   
   const { categoryState, loadingMessage, errorMessage } = useGetCategories();
   
   return(
      <IonPage>         
         <IonHeader>
            <HeaderToolbar/>                  
         </IonHeader>
         { categoryState.isLoaded 
         ? 
         <>
            <IonContent className="menu-ion-content" >
               <div className="content-container">
                  <div className="content">
                     <IonItem className="screen-ion-item" lines="none">
                        <IonImg className="da-sergio-logo"  
                           src={process.env.REACT_APP_AWS_CDN_IMAGES_URL+DaSergioLogoFileName192x233}
                           alt={DaSergioAlternateTextForImage}>
                        </IonImg>
                        <IonText class="screen-ion-text da-sergio-text">
                           <p><span className="menu-text-heading">Pizzeria Ristorante <br/>da Sergio</span></p>
                           <p>
                              <span className="menu-text-heading">Öffnungs- und Lieferzeiten</span><br/>
                              <p><span className="menu-text-heading">Montag - Freitag<br/>(auch an Feiertagen)<br/></span>
                              <span className="menu-text-block">10:30 - 14:00 Uhr<br/>16:30 - 21:00 Uhr<br/></span></p>
                           </p>
                           <p>
                              <span className="menu-text-heading">Samstag und Sonntag<br/>Ruhetag</span>
                           </p>
                        </IonText>
                     </IonItem>
                     <IonGrid className="da-sergio-max-width menu-ion-grid">                     
                        <IonRow>                     
                           {categoryState.categories.map(category => {
                              return(
                                 <IonCol 
                                    size-xs="12"  
                                    size-lg="6" 
                                    key={category.category_id}
                                 >
                                    <IonItem className="menu-grid-row-ion-item" lines="none" detail={false} mode={"md"} button onClick={() => { history.push('/speisen/' + category.name) } } >
                                       <IonThumbnail className="menu-grid-col-ion-thumbnail" slot="start">
                                          <img src={category.image_path} alt={category.name}/>
                                       </IonThumbnail>
                                       <IonLabel className="da-sergio-heading-text">
                                          {category.name}
                                       </IonLabel>
                                       <IonBadge className="menu-grid-col-ion-price-text">{category.number_of_products}</IonBadge> 
                                    </IonItem>
                                 </IonCol>
                              ); 
                           })}
                        </IonRow>
                     </IonGrid>
                  </div>
                  <StickyFooterComponent/>                     
               </div>
            </IonContent>
         </>
         :
            renderLoadingOrError(categoryState.isError, errorMessage, categoryState.isLoading, loadingMessage)
         } 
      </IonPage>
   );
}

export default MenuComponent;