import { useCallback, useEffect, useState } from "react";

import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

const oneThousand:number = 1000;

export function getUtcDateUnixTimeStamp (cacheTtlInDays:number): number {
    let utcDate = new Date().setUTCDate(new Date().getUTCDate()+cacheTtlInDays);
    let utcDateUnixTimeStamp = Math.floor(utcDate.valueOf() / oneThousand);  
    return utcDateUnixTimeStamp;
}

export function getCurrentUtcDateUnixTimeStampInSeconds() {
    return Math.floor(Date.now() / oneThousand);
}

export async function getFromStorageAsync (key:string):Promise<{value: string|null}> {
    return await Storage.get({ key: key });
}

export async function setInStorageAsync (key:string, value:string) {
    await Storage.set({ key: key, value: value });
}

export function useStickyState<T>(key:string, defaultValue: T) {

    const [ storedValue, setStoredValue] = useState<T>();    
    
    const getStoredValueAsync = useCallback(async () => {
        let stickyValue = defaultValue;
        
        try {
            let tmpStickyValue:string = await (await getFromStorageAsync(key)).value!;
            if (tmpStickyValue !== null) {
                stickyValue = JSON.parse(tmpStickyValue) as T;
            }
            else {
                stickyValue = defaultValue;
            }
        }
        finally {
            setStoredValue(stickyValue);
        }
    }, [key, defaultValue]);

    async function updateStorageAsync(newValue:T) {    
        try {
            await setInStorageAsync(key, JSON.stringify(newValue));
        }   
        finally {
            getStoredValueAsync();
        } 
    }

    useEffect(() => {
        getStoredValueAsync();
    }, [getStoredValueAsync]);

    return [storedValue, updateStorageAsync] as const;
}