import '../location/CustomGoogleMapsMarker.scss';

import { DaSergioLogoFileName100x100 } from '../../helpers/Constants';
import { IonImg } from "@ionic/react";
import React from "react";

interface GoogleMapsMarkerProps {
    lat:number;
    lng:number;
}

const CustomGoogleMapsMarker: React.FC<GoogleMapsMarkerProps> = () => {

    return(
            <IonImg 
                src={process.env.REACT_APP_AWS_CDN_IMAGES_URL+DaSergioLogoFileName100x100}
                alt="logo-da-sergio" 
                className="google-maps-marker pulse" >                
            </IonImg>
    );
}

export default CustomGoogleMapsMarker;