import '../toolbars/toolbar.scss';

import AllergensGuidance from '../content/AllergensGuidance';
import CopyRight from '../content/CopyRightComponent';
import { IonItem } from "@ionic/react";
import React from "react";

const StickyFooterComponent: React.FC = () => {

    return(
        <div className="sticky-footer">
            <IonItem className="menu-ion-item-copyright" lines="none">
                <AllergensGuidance/>
            </IonItem>
            <IonItem className="menu-ion-item-copyright" lines="none">
                <CopyRight/>
            </IonItem>
        </div>
    );
}

export default StickyFooterComponent;