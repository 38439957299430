import { getCurrentUtcDateUnixTimeStampInSeconds, getFromStorageAsync, getUtcDateUnixTimeStamp, setInStorageAsync } from './StorageHelper';

import { CategoryName } from '../interfaces/Category';
import { Product } from '../interfaces/Product';
import { ProductStorageItem } from './interfaces/StorageItems';

const productsKey: string = 'products-';
const cacheTtlInDays:number = 7;

export async function setProductsInStorageAsync(categoryName:CategoryName | undefined, value: Product[]) {
  const productsStorageItem:ProductStorageItem = {
    products:value,
    utcDateUnixTimeStampInSeconds: getUtcDateUnixTimeStamp(cacheTtlInDays)
  };

  await setInStorageAsync(productsKey+categoryName, JSON.stringify(productsStorageItem));
}

export async function getProductsInCategoryFromStorageAsync(categoryName:CategoryName): Promise<Product[] | undefined> {

  const products = await getFromStorageAsync(productsKey+categoryName);
  const productStorageItem:ProductStorageItem = JSON.parse(products.value!);

  if (productStorageItem !== null) {
    if (getCurrentUtcDateUnixTimeStampInSeconds()<productStorageItem.utcDateUnixTimeStampInSeconds) {
      return productStorageItem.products;
    }
  }

  return undefined;
}