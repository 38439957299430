import { getCurrentUtcDateUnixTimeStampInSeconds, getFromStorageAsync, getUtcDateUnixTimeStamp, setInStorageAsync } from './StorageHelper';

import { Category } from '../interfaces/Category';
import { CategoryStorageItem } from './interfaces/StorageItems';

const categoriesKey: string = 'categories';
const cacheTtlInDays:number = 14;

export async function setCategoriesInStorageAsync(value: Category[]) {
  const categoryStorageItem:CategoryStorageItem = {
      categories: value,
      utcDateUnixTimeStampInSeconds: getUtcDateUnixTimeStamp(cacheTtlInDays)
  };

  await setInStorageAsync(categoriesKey, JSON.stringify(categoryStorageItem));
}

export async function getCategoriesFromStorageAsync(): Promise<Category[] | undefined> {

  const categories = await getFromStorageAsync(categoriesKey);
  const categoryStorageItem:CategoryStorageItem = JSON.parse(categories.value!);

  if (categoryStorageItem !== null) {

    if (getCurrentUtcDateUnixTimeStampInSeconds()<categoryStorageItem.utcDateUnixTimeStampInSeconds) {
      return categoryStorageItem.categories;
    }
  }

  return undefined;
}