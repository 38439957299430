import { errorGettingCategoriesAction, gettingCategoriesAction, gotCategoriesAction } from "../state/reducers/CategoriesReducer";
import { getCategoriesFromStorageAsync, setCategoriesInStorageAsync } from "../storage/CategoriesStorage";
import { useContext, useEffect, useState } from "react";

import { CategoriesContext } from "../state/providers/CategoriesProvider";
import { Category } from "../interfaces/Category";
import { axiosInstance } from "../api-client/axiosInstance";

const useGetCategories = () => {

    const { categoryState , dispatch } = useContext(CategoriesContext);
    const [ errorMessage, setErrorMessage] = useState('');
    const [ loadingMessage, setLoadingMessage] = useState('');

    useEffect(() => {
      
        const getCategories = async () => {
            dispatch(gettingCategoriesAction());         
            setLoadingMessage("Speisen werden geladen...");
            var categories:Category[] | undefined = await getCategoriesFromStorageAsync();
        
            if(categories !== undefined) {             
                dispatch(gotCategoriesAction(categories));           
            }
            else { 
                
                await axiosInstance.get<Category[]>('categories')
                    .then((response) => {
                        dispatch(gotCategoriesAction(response.data));
                        setCategoriesInStorageAsync(response.data);
                    })
                    .catch((error) => {                        
                        dispatch(errorGettingCategoriesAction());     
                        setErrorMessage("Speisen konnten nicht geladen werden. Sie können uns gerne anrufen, wir freuen uns auf Ihren Anruf.");               
                    })
            }
        }   

        getCategories();

    }, [dispatch]);

    return { categoryState, loadingMessage, errorMessage };
}

export default useGetCategories;