import '../loading/LoadingComponent.scss';

import { IonContent, IonLoading } from "@ionic/react";

import React from "react";

interface LoadingProps {
   message:string;
}

const LoadingComponent: React.FC<LoadingProps> = ({message}) => {  
   
   return(
      <>          
         <IonContent className="loading-ion-content"> 
         <IonLoading
            isOpen={true}
            message={ message }
            duration={0}
            animated={true}
            backdropDismiss={false}
            keyboardClose={true}
            showBackdrop={true}
            spinner="bubbles"
            translucent={false}
            />           
         </IonContent>
      </>
   );
}

export default LoadingComponent;