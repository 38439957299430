import { getCurrentUtcDateUnixTimeStampInSeconds, getFromStorageAsync, getUtcDateUnixTimeStamp, setInStorageAsync } from "./StorageHelper";

import { Allergen } from "../interfaces/Product";
import { AllergenStorageItem } from "./interfaces/StorageItems";

const allergensKey: string = 'allergens';
const cacheTtlInDays:number = 20;

export async function setAllergensInStorageAsync(value: Allergen[]) {
  const allergenStorageItem:AllergenStorageItem = {
      allergens: value,
      utcDateUnixTimeStampInSeconds: getUtcDateUnixTimeStamp(cacheTtlInDays)
  };

  await setInStorageAsync(allergensKey, JSON.stringify(allergenStorageItem));
}

export async function getAllergensFromStorageAsync(): Promise<Allergen[] | undefined> {

  const allergens = await getFromStorageAsync(allergensKey);
  const allergenStorageItem:AllergenStorageItem = JSON.parse(allergens.value!);

  if (allergenStorageItem !== null) {

    if (getCurrentUtcDateUnixTimeStampInSeconds() < allergenStorageItem.utcDateUnixTimeStampInSeconds) {
      return allergenStorageItem.allergens;
    }
  }

  return undefined;
}