import { Allergen } from "../../interfaces/Product";
import { AllergensState } from "./interfaces/AllergensState";

enum AllergensAction {
    Getting_Allergens = 'GettingAllergens',
    Got_Allergens = 'GotAllergens',
    Error_Getting_Allergens = 'ErrorGettingAllergens'
}

export function gettingAllergensAction() {
    return {
        type: AllergensAction.Getting_Allergens
    } as const
}

export function gotAllergensAction(allergens: Allergen[]) {
    return {
        type: AllergensAction.Got_Allergens,
        allergens: allergens
    } as const
}

export function errorGettingAllergensAction() {
    return {
        type: AllergensAction.Error_Getting_Allergens
    } as const
}

export type AllergensActions = ReturnType<typeof gettingAllergensAction | typeof gotAllergensAction | typeof errorGettingAllergensAction>;

export const AllergensReducer = (state: AllergensState, action: AllergensActions): AllergensState => {
    switch(action.type) {
        case AllergensAction.Getting_Allergens: {
            return { 
                ...state,
                isError: false,
                isLoading: true,                
                isLoaded: false
            };
        }
        case AllergensAction.Got_Allergens: {
            return {
                ...state,
                allergens: action.allergens,
                isError: false,
                isLoading: false,
                isLoaded: true
            };
        }
        case AllergensAction.Error_Getting_Allergens: {            
            return {
                ...state,
                isError: true,
                isLoading: false,
                isLoaded: false
            };
        }
        default:
            return state;
    }
}

const logger = (reducer: any) => {
    const reducerWithLogger = (state: AllergensState, action: AllergensActions) => {
      console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
      console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
      console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state, action));
      return reducer(state,action);
    };
    
    return reducerWithLogger;
}

export const AllergensLoggerReducer = logger(AllergensReducer);