import { ProductsInCategoryState, ProductsState } from "./interfaces/ProductsState";

import { CategoryName } from "../../interfaces/Category";
import { Product } from "../../interfaces/Product";

enum ProductsAction {
    Getting_Products_In_Category = 'GettingProductsInCategory',
    Got_Products_From_Category = 'GotProductsFromCategory',
    Error_Getting_Products_From_Category = 'ErrorGettingProductsFromCategory'
}

export function gettingProductsAction(categoryName: CategoryName) {
    return {
        type: ProductsAction.Getting_Products_In_Category,
        categoryName: categoryName
    } as const
}

export function gotProductsAction(categoryName: CategoryName, products: Product[]) {
    return {
        type: ProductsAction.Got_Products_From_Category,
        categoryName: categoryName,
        products: products
    } as const
}

export function errorGettingProductsAction(categoryName: CategoryName) {
    return {
        type: ProductsAction.Error_Getting_Products_From_Category,
        categoryName: categoryName
    } as const
}

export type ProductsActions = ReturnType<typeof gettingProductsAction | typeof gotProductsAction | typeof errorGettingProductsAction>;

export function ProductsReducer (state: ProductsInCategoryState, action: ProductsActions): ProductsInCategoryState {
    switch(action.type) {
        case ProductsAction.Getting_Products_In_Category: { 

            let newKeyEntry:{[categoryId: string]: ProductsState} = { };
            newKeyEntry[action.categoryName] = { isError: false, isLoading: true, isLoaded: false, products: [] };
 
            return { 
                ...state,
                productsInCategory: { ...state.productsInCategory, ...newKeyEntry }
            };                             
        }        
        case ProductsAction.Got_Products_From_Category: { 
            let newKeyEntry:{[categoryId: string]: ProductsState} = { };
            newKeyEntry[action.categoryName]= { isError: false, isLoading: false, isLoaded: true, products: action.products };

            return { 
                ...state,
                productsInCategory: { ...state.productsInCategory, ...newKeyEntry }
            };   
        }
        case ProductsAction.Error_Getting_Products_From_Category: {  
            let newKeyEntry:{[categoryId: string]: ProductsState} = { };
            newKeyEntry[action.categoryName]= { isError: true, isLoading: false, isLoaded: false, products: [] };

            return { 
                ...state,
                productsInCategory: { ...state.productsInCategory, ...newKeyEntry }
            }; 
        }
        default:
            return state;
    }
}

const logger = (reducer: any) => {
    const reducerWithLogger = (state: ProductsInCategoryState, action: ProductsActions) => {
      console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
      console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
      console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state, action));
      return reducer(state,action);
    };
    
    return reducerWithLogger;
}

export const ProductsLoggerReducer = logger(ProductsReducer);