import '../content/content.scss';

import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useState } from "react";

import ErrorComponent from '../error/ErrorComponent';
import LoadingComponent from '../loading/LoadingComponent';
import { WkoAllergensInfoURL } from '../../helpers/Constants';
import { close } from "ionicons/icons";
import useGetAllergens from '../../helpers/GetAllergensCustomerReactHook';

const AllergensGuidance: React.FC = () => {
    const [showAllergensGuidanceModal, setShowAllergensGuidanceModal] = useState(false);
    
    // Dont call api on first render
    const { allergensState, loadingMessage, errorMessage, callApi } = useGetAllergens();
     
    // TODO: api call should only be send in case the modal is opened
    function openModal() {      
        callApi();
        setShowAllergensGuidanceModal(true);
     }

      // TODO: function is invoke in other component and should be shared
   function renderLoadingOrError() {
   
        if(allergensState.isError) {            
            return <ErrorComponent message={errorMessage}/>;
        }
        else if(allergensState.isLoading) {
            return <LoadingComponent message={loadingMessage}/>;
        }
    }

    return( 
        <>
            <IonModal isOpen={showAllergensGuidanceModal} swipeToClose={true} onDidDismiss={() => setShowAllergensGuidanceModal(false)}>                
                <IonHeader>
                    <IonToolbar className="content-ion-toolbar">
                        <IonTitle>Allergeninformation</IonTitle>
                        <IonButtons slot="end">
                            <IonButton className="content-ion-button" slot="icon-only" onClick={() => setShowAllergensGuidanceModal(false)}>
                            <IonIcon icon={close} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {allergensState.isLoaded
                ?
                <>
                <IonContent className="content-ion-content">                  
                        <IonList className="content-allergens-ion-list" inset={false} >
                            {allergensState.allergens.map((allergen, index) => {
                                return (
                                    <IonItem key={index} className="content-allergens-ion-item" lines="none">
                                        <IonAvatar className="content-allergens-ion-avatar" slot="start">                                            
                                            <img src={allergen.image_path} alt={allergen.heading} />
                                        </IonAvatar>
                                        <IonText className="content-allergens-ion-text">
                                            <h5>{ allergen.letter_code.toUpperCase()} - {allergen.heading.toUpperCase() } </h5>        
                                            <p className="da-sergio-text">{allergen.description}</p>
                                        </IonText>
                                    </IonItem>                                   
                                )
                            })}
                        </IonList>                    
                    <IonText className="content-ion-text-hyperlink">
                    <a rel="noopener noreferrer" className="content-wko-link" target="_blank" href={WkoAllergensInfoURL}>{WkoAllergensInfoURL}</a>
                    </IonText>
                </IonContent>
                </>
             :
             renderLoadingOrError()
            } 
            </IonModal> 
            <IonText className="da-sergio-text content-ion-text" onClick={() => openModal()}>Allergeninformation</IonText>
        </>
    );
}

export default AllergensGuidance;