import { CategoriesActions, CategoriesLoggerReducer, CategoriesReducer } from "../reducers/CategoriesReducer";
import React, { createContext, useReducer } from "react";

import { CategoriesState } from "../reducers/interfaces/CategoriesState";

const InitCategoriesState: CategoriesState = {
    categories: [],
    isLoading: false,
    isLoaded: false,
    isError: false
 };

type CategoriesContextValue = {
    categoryState: CategoriesState
    dispatch: React.Dispatch<CategoriesActions>
}

export const CategoriesContext = createContext<CategoriesContextValue>({
    categoryState: InitCategoriesState,
    // must be defined but is overwritten from the context provider.
    dispatch: () => { }
  });

export function CategoriesContextProvider(props: any){
  
    const reducer = process.env.REACT_APP_ENV === 'production' ? CategoriesReducer : CategoriesLoggerReducer;
    const [categoryState, dispatch] = useReducer(reducer, InitCategoriesState);  
      
    return (<CategoriesContext.Provider value={{ categoryState, dispatch }}>{props.children}</CategoriesContext.Provider>);
} 