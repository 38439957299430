import '../error/ErrorComponent.scss';

import { IonContent, IonItem, IonText } from "@ionic/react";

import React from "react";

interface ErrorProps {
    message:string;
}

const ErrorComponent: React.FC<ErrorProps> = ({message}) => {   
    
    // TODO: Try to place logo here until the customer are seeing the error.
    return(    
        <>      
            <IonItem className="error-ion-item">
                <IonText className="error-ion-text da-sergio-text">{ message }</IonText>
            </IonItem>  
            <IonContent className="error-ion-content"></IonContent>  
        </>
    );
}

export default ErrorComponent;