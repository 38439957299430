import { AllergensActions, AllergensLoggerReducer, AllergensReducer } from "../reducers/AllergensReducer";
import React, { createContext, useReducer } from "react";

import { AllergensState } from "../reducers/interfaces/AllergensState";

const InitAllergensState: AllergensState = {
    allergens: [],
    isLoading: false,
    isLoaded: false,
    isError: false
 };

type AllergensContextValue = {
    allergensState: AllergensState
    dispatch: React.Dispatch<AllergensActions>
}

export const AllergensContext = createContext<AllergensContextValue>({
    allergensState: InitAllergensState,
    // must be defined but is overwritten from the context provider.
    dispatch: () => { }
});

export function AllergensContextProvider(props: any) {
  
    const reducer = process.env.REACT_APP_ENV === 'production' ? AllergensReducer : AllergensLoggerReducer;  
    const [allergensState, dispatch] = useReducer(reducer, InitAllergensState);  
      
    return (<AllergensContext.Provider value={{ allergensState, dispatch }}>{props.children}</AllergensContext.Provider>);
} 
