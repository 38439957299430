/* Core CSS required for Ionic components to work properly */

import '@ionic/react/css/core.css';
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import './theme/variables.scss'

import { IonApp, IonRouterOutlet, setupConfig } from '@ionic/react';
import { Redirect, Route } from 'react-router';

import { AllergensContextProvider } from './state/providers/AllergensProvider';
import { CategoriesContextProvider } from './state/providers/CategoriesProvider';
import GoogleMapsComponent from './components/location/GoogleMapsComponent';
import { IonReactHashRouter } from '@ionic/react-router'
import MenuComponent from './components/menu/MenuComponent';
import ProductsInCategoryComponent from './components/product/ProductsInCategoryComponent';
import React from 'react';
import SideMenuComponent from './components/sidemenu/SideMenuComponent';
import { isPlatform } from '@ionic/react';

setupConfig({
  swipeBackEnabled: isPlatform('ios') ? false : true
});

const App: React.FC = () => (  
    <IonApp>  
      <CategoriesContextProvider>
        <AllergensContextProvider>                
              <IonicApp/>
          </AllergensContextProvider>
      </CategoriesContextProvider>
    </IonApp> 
); 

const IonicApp: React.FC = () => (   
    <IonReactHashRouter>
      <SideMenuComponent />
      <IonRouterOutlet animated={isPlatform('ios') ? false : true} id="main">                
        <Route path="/speisekarte" component={MenuComponent} />               
        <Route path="/speisen/:category_name" component={ProductsInCategoryComponent} />  
        <Route path="/location" component={GoogleMapsComponent} />        
        <Redirect from="/" to="/speisekarte" />
      </IonRouterOutlet>
    </IonReactHashRouter>
);

export default App;